<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" style="height: 25px" />
    <el-backtop target=".el-main"></el-backtop>

    <div class="search">
      <el-select
        v-model="form.status"
        placeholder="请选择审核类型"
        @change="handleStatusNow"
      >
        <el-option label="CPLE 审核成功" value="2"></el-option>
        <el-option label="CPLE 审核失败" value="3"></el-option>
        <el-option label="奖励发放中" value="4"></el-option>
      </el-select>
      <el-select
        v-model="valueArea"
        filterable
        clearable
        placeholder="请选择区号(可搜索关键字)"
        style="width: 220px"
        v-if="internationType"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name + item.tel"
          :value="item.tel"
        >
        </el-option>
      </el-select>
      <el-input placeholder="请输入手机号" v-model="form.phone"></el-input>
      <el-input
        placeholder="请输入要查询的钱包地址"
        style="width: 300px"
        v-model="form.walletUrl"
      ></el-input>
      <el-date-picker
        v-model="form.date"
        type="date"
        placeholder="选择日期"
        value-format="yyyy-MM-dd"
        :clearable="false"
      >
      </el-date-picker>

      <div>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>
    </div>

    <div class="table_con" style="height: calc(100vh - 200px)">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        ref="multipleTable"
        tooltip-effect="dark"
        id="mytable"
      >
        <el-table-column label="序号" type="index" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" width="130"> </el-table-column>
        <el-table-column prop="userName" label="姓名" width="130">
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="160">
          <template slot-scope="scope">
            {{ scope.row.phoneArea }} {{ scope.row.phone }}
          </template>
        </el-table-column>
        <el-table-column prop="cost" label="手续费"> </el-table-column>
        <el-table-column prop="wallet" label="转账地址" width="230">
        </el-table-column>
        <el-table-column prop="cple" label="CPLE转出金额" width="130">
        </el-table-column>
        <el-table-column prop="txid" label="txid" width="300">
        </el-table-column>
        <el-table-column prop="auditor" label="审核人" width="130">
        </el-table-column>
        <el-table-column prop="submitTime" label="提交转出时间" width="190">
        </el-table-column>
        <el-table-column prop="examineTime" label="审核时间" width="190">
        </el-table-column>

        <el-table-column
          prop="rejectReason"
          label="失败原因"
          width="160"
          v-if="rejectReasonType"
        >
        </el-table-column>
        <el-table-column fixed="right" label="状态" width="100" align="center">
          <template slot-scope="scope">
            <div class="operation" v-if="scope.row.examineStatus == '审核成功'">
              审核成功
            </div>
            <div
              class="operation"
              v-else-if="scope.row.examineStatus == '待审核'"
            >
              <el-button
                type="text"
                size="small"
                @click="handleCPLEexamine(scope.row, 1)"
                >审核通过</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="handleCPLEexamine(scope.row, 2)"
                >审核拒绝</el-button
              >
            </div>
            <div
              class="operation"
              v-else-if="scope.row.examineStatus == '审核失败'"
            >
              审核失败
            </div>
            <div
              class="operation"
              v-else-if="scope.row.examineStatus == '奖励发放中'"
            >
              奖励发放中
            </div>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import { showLoading, hideLoading } from "@/util/loading";

import { areaCodeData } from "@/util/areaCodeData";
import { format_time_date } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },

  inject: ["reload"],
  data() {
    return {
      form: {
        status: "2",
        phone: "",
        date: "",
        walletUrl: "",
      },
      tableData: [],
      multipleSelection: [],
      rejectReasonType: false,

      valueArea: "",
      options: [],

      BreadcrumbCon: [
        {
          name: "资产审核",
        },
        {
          name: "CPLE积分上链审核",
        },
        {
          name: "已审核列表",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100, 200, 500],

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.getCpleUpperChainList,
      method: "POST",
      params: JSON.stringify({
        examineStatus: "2",
        nonce: "",
        pageNum: 0,
        pageSize: 0,
        phone: "",
        phoneArea: this.phoneAreaROLE,
        sign: "",
        submitTime: "",
        token: "",
        wallet: "",
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;

          res.data.data.list.map((item) => {
            this.changeItem(item);
          });
          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      if (this.form.phone || this.form.date || this.form.walletUrl) {
        showLoading();
        const opt = {
          url: reqUrl.getCpleUpperChainList,
          method: "POST",
          params: JSON.stringify({
            examineStatus: this.form.status,
            nonce: "",
            pageNum: 0,
            pageSize: 0,
            phone: this.form.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            sign: "",
            submitTime: this.form.date,
            token: "",
            wallet: this.form.walletUrl,
          }),
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;

              res.data.data.list.map((item) => {
                this.changeItem(item);
              });
              hideLoading();
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            hideLoading();
            console.log(err, "err1");
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    handleStatusNow() {
      if (this.form.status == "3") {
        this.rejectReasonType = true;
      }
      if (this.form.status == "2") {
        this.rejectReasonType = false;
      }
      showLoading();
      const opt = {
        url: reqUrl.getCpleUpperChainList,
        method: "POST",
        params: JSON.stringify({
          examineStatus: this.form.status,
          nonce: "",
          pageNum: 0,
          pageSize: 0,
          phone: "",
          phoneArea: this.valueArea || this.phoneAreaROLE,
          sign: "",
          submitTime: "",
          token: "",
          wallet: "",
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            res.data.data.list.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getCpleUpperChainList,
        method: "POST",
        params: JSON.stringify({
          examineStatus: this.form.status,
          nonce: "",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          phone: this.form.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          sign: "",
          submitTime: this.form.date,
          token: "",
          wallet: this.form.walletUrl,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;

            res.data.data.list.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    changeItem(item) {
      if (item.examineStatus == 1) {
        item.examineStatus = "待审核";
      } else if (item.examineStatus == 2) {
        item.examineStatus = "审核成功";
      } else if (item.examineStatus == 3) {
        item.examineStatus = "审核失败";
      } else if (item.examineStatus == 4) {
        item.examineStatus = "奖励发放中";
      }

      if (item.auditor == "qcxAdmin") {
        item.auditor = "趣出行管理员";
      } else if (item.auditor == "auto") {
        item.auditor = "自动";
      }

      item.submitTime = format_time_date(item.submitTime);
      item.examineTime = format_time_date(item.examineTime);
    },

    reset() {
      if (this.form.phone || this.form.date || this.form.walletUrl) {
        this.form.phone = "";
        this.form.date = "";
        this.form.walletUrl = "";

        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  width: 80%;
  display: flex;
  flex-wrap: wrap;

  .el-input {
    width: 200px;
    margin-right: 10px;
  }

  .el-select {
    margin-right: 10px;
  }
}

.operation {
  display: flex;
  flex-direction: column;
  color: #b4b4b4;
  font-weight: bold;

  .el-button + .el-button {
    margin-left: 0;
    margin-top: 2px;
  }

  .el-button {
    border: 1px #ccc solid;
    color: #666;
    background: #d9d9d9;
  }
}
</style>
